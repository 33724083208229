<template>
  <div
    class="projectCard"
    :class="{'active': project.id === activeProject.id}"
    @click="emits('changeActiveProject', {id: project.id})"
  >
    <div class="imgWrapper adaptive-img">
      <NuxtImg
        :src="String(project?.imageId)"
        loading="lazy"
        placeholder
        alt="alt"
        width="140"
        height="130"
        fit="crop"
      />
    </div>
    <div class="projectCard-description">
      <h2 class="title">{{ project.name }}</h2>
      <div class="items">
        <div
          v-if="projectPrice"
          class="item"
        >
          <img
            src="~/assets/img/svg/project/house.svg"
            alt="house"
          >
          <span>{{ `Квартиры от ${projectPrice} млн.р.` }}</span>
        </div>
        <div class="item">
          <img
            src="~/assets/img/svg/project/location.svg"
            alt="location"
          >
          <span>{{ projectAddress }}</span>
        </div>
      </div>
      <div class="project-arrow__img --mob">
        <span>Перейти к проекту</span>
        <img
          src="~/assets/img/svg/custom-section/arrow-left-mob.svg"
          alt="arrow"
        >
      </div>
    </div>
    <div class="project-arrow__img --desc">
      <img
        src="~/assets/img/svg/custom-section/arrow-left.svg"
        alt="arrow"
      >
    </div>
    <NuxtLink
      class="link-absolute"
      :to="project.url"
    />
  </div>
</template>

<script setup lang="ts">
import type { ICategory } from '#sitis/internal/controllers/categories/models/Category';
import { priceFormat } from '#sitis/plugins/priceFormat';

const { project } = defineProps<{
	project: ICategory,
	activeProject: { id: string | number }
}>();

const emits = defineEmits<{
	changeActiveProject: [project: { id: string | number }]
}>();

const projectPrice = computed(() => {
  const minPrice = project.dynamicFields?.min_price;
  return minPrice ? priceFormat({ amount: minPrice / 1000000, decimalCount: 2, decimal: ',' }) : '';
});

const projectAddress = computed(() => {
  const street = project.dynamicFields?.street || '';
  const number = project.dynamicFields?.number || '';

  return `${street}, ${number}`;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.projectCard {
	position: relative;
	background: var(--white);
	padding: 12px;
	border-radius: 22px;
	display: flex;
	gap: 20px;
	cursor: pointer;
	transition: box-shadow .2s linear;
	align-items: center;

	&:hover {
		box-shadow: 0 1px 10px #044e921f;
	}

	&.active {
		box-shadow: 0 1px 10px #044e921f;
	}

	.imgWrapper {
		width: 140px;
		height: 130px;
		border-radius: 10px;

		& > img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.projectCard-description {
	display: flex;
	flex-direction: column;

	& > .title {
		color: var(--primary);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%; /* 26.4px */
		letter-spacing: -0.96px;
		margin-bottom: 14px;
	}

	& > .items {
		display: flex;
		flex-direction: column;
		gap: 10px;

		& > .item {
			display: flex;
			gap: 5px;
			align-items: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 110%; /* 28.509px */
			letter-spacing: -0.32px;
		}
	}
}

.project-arrow__img {
	&.--desc{
		margin-left: auto;
	}

	&.--mob{
		display: none;
	}
}

@include media('lg') {
	.officeCard {
		flex-direction: column;
	}

	.projectCard {
		flex-direction: column;
		align-items: flex-start;

		& .project-arrow__img {
			&.--desc {
				display: none;
			}

			&.--mob{
				display: flex;
				align-items: center;
				gap: 5px;
				margin-top: 14px;

				& >span{
					font-size: 10px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%; /* 13px */
					letter-spacing: -0.2px;
					color: var(--primary);

				}
			}
		}

		.imgWrapper {
			width: 100%;
			height: 165px;
			border-radius: 22px;
		}
	}
}

@include media('md') {
	.projectCard {
		flex-direction: row;
		gap: 12px;
		padding: 8px;

		.imgWrapper {
			width: 85px;
			min-width: 85px;
			height: 100%;

			&::before{
				padding-top: 100%;
			}
		}
	}

	.projectCard-description {
		display: flex;
		flex-direction: column;
		gap: 0;

		& > .title {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 18.2px */
			letter-spacing: -0.28px;
			text-transform: uppercase;
			color: var(--primary-80);
			margin-bottom: 6px;
		}

	& > .items{
		gap: 5px;

		& > .item {
			display: flex;
			gap: 5px;
			align-items: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 110%;
			letter-spacing: -0.24px;

			& > img {
				width: 16px;
				height: 16px;
			}
		}
	}

		& > a {
			font-size: 10px;
			font-style: normal;
			font-weight: 700;
			line-height: 130%; /* 13px */
			letter-spacing: -0.2px;
			margin-top: 6px;
		}
	}
}
</style>
